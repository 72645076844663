<template>
    <div class="addERC20-wrap">
        <CRow>
            <CCard class="cardERC-form">
                <CCardBody v-on:change="isFormChange=true">
                    <ValidationObserver ref="observer" @submit.prevent="userAction">
                        <CForm>
                            <ValidationProvider :name="$t('EDIT_ERC20_PAGE_FIELD_NAME_VALIDATE_PLATFORM')">
                                <div class="select-wrap form-group">
                                <label>{{$t("EDIT_ERC20_PAGE_LABEL_PLATFORM")}}</label>
                                <select class="form-control role-select" v-model="platform.platform" disabled>
                                    <option
                                            v-for="option in options"
                                            v-bind:value="option.symbol"
                                            :key="option.symbol"
                                    >{{ option.symbol }}</option>
                                </select>
                            </div>
                            </ValidationProvider>
                            <div class="form-group file-area-group">
                                <!-- <label for="icon"></label> -->
                                <div class="file-area">
                                    <img :src="srcImg || platform.icon" alt="">
                                    <div class="ip-file-group">
                                        <label for="images">+ {{$t("EDIT_ERC20_PAGE_BUTTON_ADD_LOGO")}}</label>
                                        <input type="file" name="icon" id="images" @change="uploadImage" />
                                    </div>
                                    <p class="label-file-support">{{$t("EDIT_ERC20_PAGE_LABEL_FILE_SUPPORT")}}</p>
                                </div>
                                <p class="msg-err-img" v-if="imageError===true">{{$t("EDIT_ERC20_PAGE_IMAGE_OVER_SIZE")}}</p>
                            </div>
                            <ValidationProvider :name="$t('EDIT_ERC20_PAGE_FIELD_NAME_VALIDATE_NAME')" rules="required|max:20" v-slot="{ errors }">
                                <div>
                                    <CInput
                                            :label="$t('EDIT_ERC20_PAGE_LABEL_TOKEN_NAME')"
                                            :placeholder="$t('EDIT_ERC20_PAGE_PLACEHOLDER_TOKEN_NAME')"
                                            horizontal
                                            v-model="platform.name"
                                            class="form-group--custom"
                                    />
                                    <CRow>
                                        <CCol :sm="{offset:'3'}"></CCol>
                                        <CCol :sm="9">
                                            <p class="error-msg">{{errors[0]}}</p>
                                        </CCol>
                                    </CRow>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider :name="$t('EDIT_ERC20_PAGE_FIELD_NAME_VALIDATE_SYMBOL')" rules="required|max:8" v-slot="{ errors }">
                                <div>
                                    <CInput
                                            :label="$t('EDIT_ERC20_PAGE_LABEL_TOKEN_CODE')"
                                            :placeholder="$t('EDIT_ERC20_PAGE_PLACEHOLDER_TOKEN_CODE')"
                                            horizontal
                                            v-model="platform.symbol"
                                            class="form-group--custom"
                                    />
                                    <CRow>
                                        <CCol :sm="{offset:'3'}"></CCol>
                                        <CCol :sm="9">
                                            <p class="error-msg">{{errors[0]}}</p>
                                        </CCol>
                                    </CRow>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider :name="$t('EDIT_ERC20_PAGE_LABEL_TOKEN_DECIMAL')" rules="required|min_value:0" v-slot="{ errors }">
                                <div>
                                    <CInput
                                            :label="$t('EDIT_ERC20_PAGE_PLACEHOLDER_TOKEN_DECIMAL')"
                                            :placeholder="$t('EDIT_ERC20_PAGE_PLACEHOLDER_TOKEN_DECIMAL')"
                                            horizontal
                                            type="number"
                                            v-model="platform.decimal"
                                            class="form-group--custom"
                                    />
                                    <CRow>
                                        <CCol :sm="{offset:'3'}"></CCol>
                                        <CCol :sm="9">
                                            <p class="error-msg">{{errors[0]}}</p>
                                        </CCol>
                                    </CRow>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider :name="$t('EDIT_ERC20_PAGE_FIELD_NAME_VALIDATE_TOKEN_ADDRESS')" rules="required" v-slot="{ errors }">
                                <div>
                                    <CInput
                                            :label="$t('EDIT_ERC20_PAGE_LABEL_TOKEN_ADDRESS')"
                                            :placeholder="$t('EDIT_ERC20_PAGE_PLACEHOLDER_TOKEN_ADDRESS')"
                                            horizontal
                                            v-model="platform.sc_token_address"
                                            class="form-group--custom"
                                            disabled
                                    />
                                    <CRow>
                                        <CCol :sm="{offset:'3'}"></CCol>
                                        <CCol :sm="9">
                                            <p class="error-msg">{{errors[0]}}</p>
                                        </CCol>
                                    </CRow>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider :name="$t('EDIT_ERC20_PAGE_FIELD_NAME_VALIDATE_VALIDATOR_FEE')" rules="required|min_value:0|max_value:1000" v-slot="{ errors }">
                                <div>
                                    <CInput
                                            :label="$t('EDIT_ERC20_PAGE_LABEL_VALIDATOR_FEE')"
                                            placeholder="0"
                                            type="number"
                                            horizontal
                                            v-model="platform.erc20_validator_fee"
                                            class="form-group--custom est-per"
                                    />
                                    <CRow>
                                        <CCol :sm="{offset:'3'}"></CCol>
                                        <CCol :sm="9">
                                            <p class="error-msg">{{errors[0]}}</p>
                                        </CCol>
                                    </CRow>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider :name="$t('EDIT_ERC20_PAGE_FIELD_NAME_VALIDATE_EST_REWARD')" rules="required" v-slot="{ errors }">
                                <div>
                                    <CInput
                                            :label="$t('EDIT_ERC20_PAGE_LABEL_REWARD')"
                                            placeholder="0"
                                            horizontal
                                            v-model="platform.erc20_reward_estimate"
                                            class="form-group--custom sec-label"
                                    />
                                    <label class="label-display-only col-sm-3">{{$t("EDIT_ERC20_PAGE_LABEL_DISPLAY_ONLY")}}</label>
                                    <CRow>
                                        <CCol :sm="{offset:'3'}"></CCol>
                                        <CCol :sm="9">
                                            <p class="error-msg">{{errors[0]}}</p>
                                        </CCol>
                                    </CRow>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider :name="$t('EDIT_ERC20_PAGE_FIELD_NAME_VALIDATE_DURATION')" rules="required" v-slot="{ errors }">
                                <div>
                                    <CInput
                                            :label="$t('EDIT_ERC20_PAGE_LABEL_DURATION')"
                                            :placeholder="$t('EDIT_ERC20_PAGE_PLACEHOLDER_DURATION')"
                                            horizontal
                                            v-model="platform.erc20_duration"
                                            class="form-group--custom sec-label"
                                    />
                                    <label class="label-display-only col-sm-3">{{$t("EDIT_ERC20_PAGE_LABEL_DISPLAY_ONLY")}}</label>
                                    <CRow>
                                        <CCol :sm="{offset:'3'}"></CCol>
                                        <CCol :sm="9">
                                            <p class="error-msg">{{errors[0]}}</p>
                                        </CCol>
                                    </CRow>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider :name="$t('EDIT_ERC20_PAGE_FIELD_NAME_VALIDATE_STATUS')" rules="required" v-slot="{ errors }">
                                <div class="select-wrap form-group">
                                    <label>{{$t("EDIT_ERC20_PAGE_LABEL_STATUS")}}</label>
                                    <select class="form-control role-select" v-model="platform.status">
                                        <option
                                                v-for="item in status"
                                                v-bind:value="item"
                                                :key="item">{{$t(`EDIT_ERC20_PAGE_OPTION_SELECT_${renderStatus(item)}`)}}</option>
                                    </select>
                                </div>
                                <CRow>
                                    <CCol :sm="{offset:'3'}"></CCol>
                                    <CCol :sm="9">
                                        <p class="error-msg">{{errors[0]}}</p>
                                    </CCol>
                                </CRow>
                            </ValidationProvider>
                        <div class="btn-ERC20">
                            <CButton class="erc-cancel" @click="openWarningLeaveModal">{{$t("EDIT_ERC20_PAGE_BUTTON_CANCEL")}}</CButton>
                            <CButton type="submit">{{$t("EDIT_ERC20_PAGE_BUTTON_SAVE")}}</CButton>
                        </div>
                    </CForm>
                    </ValidationObserver>
                </CCardBody>
            </CCard>
        </CRow>
        <CRow v-if="this.hasViewPayout === true">
            <CCol class="col--padding">
                <CCard>
                    <CCardBody>
                        <label class="title-items">{{$t("EDIT_ERC20_PAGE_TITLE_TOKEN_PAYOUT")}}</label>
                        <table class="table table-striped table-drag">
                            <thead class="thead-white">
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">{{$t("EDIT_ERC20_PAGE_TEXT_TOKEN_PLATFORM")}}</th>
                                <th scope="col">{{$t("EDIT_ERC20_PAGE_TEXT_TOKEN_NAME")}}</th>
                                <th scope="col">{{$t("EDIT_ERC20_PAGE_TEXT_TOKEN_CODE")}}</th>
                                <th scope="col">{{$t("EDIT_ERC20_PAGE_TEXT_COLUMN_MAX_PAYOUT")}}</th>
                                <th v-if="this.hasUpdate === true">{{$t("EDIT_ERC20_PAGE_TEXT_COLUMN_ACTION")}}</th>
                            </tr>
                            </thead>
                            <Draggable v-model="platforms" tag="tbody">
                                <tr v-for="(item, index) in platforms" :key="item.id" class="t-body">
                                    <td scope="row">{{ index + 1 }}</td>
                                    <td>{{item.platform}}</td>
                                    <td>{{item.token_name}}</td>
                                    <td>{{item.token_symbol}}</td>
                                    <td>{{item.max_payout}}</td>
                                    <td :class="item.wait_blockchain_confirm_status_flg!==false?'texts-wrap': ''" v-if="hasUpdate === true">
                                        <CButton @click="editpayout(platform,item)" :disabled="item.wait_blockchain_confirm_status_flg==true">{{$t("EDIT_ERC20_PAGE_BUTTON_EDIT")}}</CButton>
                                        <span v-if="item.wait_blockchain_confirm_status_flg!==false">{{$t("EDIT_ERC20_PAGE_TEXT_PENDING")}}</span>
                                    </td>
                                </tr>
                            </Draggable>
                        </table>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
        <CRow v-if="this.hasViewPlan === true && platform.sub_type !== 'manual'">
            <CCol class="col--padding">
                <CCard>
                    <CCardBody>
                        <label class="title-items">{{$t("EDIT_ERC20_PAGE_TITLE_STAKING_PLAN")}}</label>
                        <CButton @click="goAddnew()" class="btn btn-success btn-md btn-new" v-if="this.hasAdd === true">
                           + {{$t("EDIT_ERC20_PAGE_BUTTON_ADD_PLAN")}}
                        </CButton>
                        <table class="table table-striped table-drag">
                            <thead class="thead-white">
                            <tr>
                                <th scope="col">#</th>
                                <th>{{$t("EDIT_ERC20_PAGE_TEXT_COLUMN_STATUS")}}</th>
                                <th scope="col">{{$t("EDIT_ERC20_PAGE_TEXT_COLUMN_NAME")}}</th>
                                <th scope="col">{{$t("EDIT_ERC20_PAGE_TEXT_COLUMN_DURATION")}}</th>
                                <th scope="col">{{$t("EDIT_ERC20_PAGE_TEXT_COLUMN_REWARD")}}</th>
                                <th scope="col">{{$t("EDIT_ERC20_PAGE_TEXT_COLUMN_REWARD_TOKEN")}}</th>
                                <th v-if="this.hasUpdatePlan === true">{{$t("EDIT_ERC20_PAGE_TEXT_COLUMN_ACTION")}}</th>
                            </tr>
                            </thead>
                            <Draggable v-model="plans" tag="tbody">
                                <tr v-for="(item, index) in plans" :key="item.id" class="t-body">
                                    <td scope="row">{{ index + 1 }}</td>
                                    <td class="check-status">
                                        <span v-if="item.status === 0" class="coming-soon"><img src="@/assets/images/coming-soon.svg"/>{{$t("EDIT_ERC20_PAGE_TEXT_ITEM_STATUS_COMING_SOON")}}</span>
                                        <span v-else-if="item.status === -1" class="disabled"><img src="@/assets/images/disabled.svg"/>{{$t("EDIT_ERC20_PAGE_TEXT_ITEM_STATUS_DISABLED")}}</span>
                                        <span v-else-if="item.status === 1" class="active"><img src="@/assets/images/ico-user-active.svg"/>{{$t("EDIT_ERC20_PAGE_TEXT_ITEM_STATUS_ENABLED")}}</span>
                                        <span v-else class="hidden"><img src="@/assets/images/hidden.svg"/>{{$t("EDIT_ERC20_PAGE_TEXT_ITEM_STATUS_HIDDEN")}}</span>
                                    </td>
                                    <td>{{item.name}}</td>
                                    <td>{{item.duration}}</td>
                                    <td>{{item.reward_percentage}}</td>
                                    <td>{{item.reward_diff_token_flg}}</td>
                                    <td v-if="hasUpdatePlan === true"><CButton @click="editstakingplan(platform,item)">{{$t('EDIT_ERC20_PAGE_BUTTON_EDIT')}}</CButton></td>
                                </tr>
                            </Draggable>
                        </table>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
        
        <CModal id="leavePageModal" :show.sync="warningLeavePage" :centered="true">
            <div class="">
                <div class="tit-leave">
                <h4>{{$t('EDIT_ERC20_PAGE_MODAL_TITLE_UNSAVED_CHANGES')}}</h4>
                </div>
                <p class="text-modal">{{$t('EDIT_ERC20_PAGE_MODAL_CONTENT_UNSAVED_CHANGES')}}</p>
                <p class="text-modal">{{$t("EDIT_ERC20_PAGE_MODAL_QUESTION_UNSAVED_CHANGES")}}</p>
            </div>

            <template #footer>
                <CButton
                class="stay-btn"
                @click="warningLeavePage = false"
                color="dark"
                variant="outline"
                >{{$t('EDIT_ERC20_PAGE_MODAL_BUTTON_STAY_ON_THIS_PAGE')}}</CButton>
                <router-link class="leave-btn" to="/admin/staking-platform">{{$t('EDIT_ERC20_PAGE_MODAL_BUTTON_LEAVE_THIS_PAGE')}}</router-link>
            </template>
        </CModal>
    </div>
</template>
<script>
import Draggable from "vuedraggable";
import endpoints from "@/constants/endpoints";
import { mapState, mapActions } from "vuex";
import platform from "../../../../../store/modules/platform";
export default {
    components: {
        Draggable
    },
    data() {
        return {
            platforms: [],
            options: [],
            plans: [],
            icon: '',
            srcImg: '',
            isFormChange: false,
            warningLeavePage: false,
            imageError: false,
            status:[
                -2,
                -1,
                0,
                1,
            ],
            hasUpdate: false,
            hasAdd: false,
            hasUpdatePlan: false,
            hasViewPayout: false,
            hasViewPlan: false
        };
    },
    computed: {
        ...mapState({
            platform: state => state.platform.selectedPlatform,
            userPermission: state => state.auth.userPermission,
        }),
    },
    async mounted() {
        await this.getPlatforms();
        await this.fetchPayoutList();
        await this.fetchStakingPlan();
        await this.checkPermission();
    },
    methods: {
        ...mapActions([
            "updateERC20",
            "getSelectedPayout",
            "getSelectedPlatform",
            "getSelectedPlan"
        ]),
        async fetchPayoutList(){
            try {
                let result = await this.$http.get(
                    endpoints.getStakingPayout(this.platform.id)
                );
                if (result.data) {
                    this.platforms=result.data
                    this.total=result.data.length
                    return result.data
                }
            } catch (err) {
                this.$notify({
                    group: "notifications",
                    type: "error",
                    title: responseHelper.getMessageByCode('ERROR_COMPONENT_TITLE'),
                    text: responseHelper.getErrorMessage(err),
                });
            }
        },
        async fetchStakingPlan(){
            try {
                let result = await this.$http.get(
                    endpoints.getStakingPlan(this.platform.id)
                );
                if (result.data) {
                    this.plans=result.data.items;
                    this.total=result.data.length;
                    return result.data
                }
            } catch (err) {
                this.$notify({
                    group: "notifications",
                    type: "error",
                    title: responseHelper.getMessageByCode('ERROR_COMPONENT_TITLE'),
                    text: responseHelper.getErrorMessage(err),
                });
            }
        },
        async getPlatforms() {
            let result = await this.$http.get(
                endpoints.getStakingPlatformConfig,
            );
            if (result.data) {
                this.options = result.data
                console.log(result.data)
            }
        },
        uploadImage(event) {
            const image = event.target.files[0];
            this.imageError=false;
            if(image.size>300000 && (image.type!=='image/png' || image.type!=='image/jpg' || image.type!=='image/svg' || image.type!=='image/jpeg')){
                this.imageError=true;
                this.srcImg='';
                return;
            }
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = e => {
                this.icon = image;
                this.srcImg = e.target.result;
                console.log(this.srcImg);
            };
        },
        async userAction() {
            const isValid = await this.$refs.observer.validate();
            if (!isValid) return;
            const payload = {
                ...this.platform,
                icon: this.icon,
            }
            const response = await this.updateERC20(payload);
            if (response) {
                this.$router.back()
            }
        },
        renderStatus(item) {
            if (item === -2) {
                return 'HIDDEN'
            }
            if (item === -1) {
                return 'DISABLED'
            }
            if (item === 0) {
                return 'COMING_SOON'
            }
            if (item === 1) {
                return 'ENABLED'
            }
        },
        editpayout(platform,item) {
            this.getSelectedPayout(item);
            this.$router.push(`/admin/staking-platform/erc20/edit-payout-max/${platform.id}/payouts/${item.id}`);

        },
        editstakingplan(platform,item) {
            this.getSelectedPlan(item);
            this.$router.push(`/admin/staking-platform/erc20/edit-staking-plan/${platform.id}/plans/${item.id}`);

        },
        goAddnew(){

            this.$router.push(`/admin/staking-platform/erc20/${this.platform.id}/add-plan`);
        },
        openWarningLeaveModal(){
            if(this.isFormChange){
                this.warningLeavePage=true;
            }else{
                this.$router.push('/admin/staking-platform');
            }
            console.log(this.isFormChange);
        },
        checkPermission(){
            if(this.userPermission.length > 0){
                this.userPermission.forEach(items =>{
                    if(items.name === 'UPDATE_PAYOUT_STAKING_PLATFORM'){
                        this.hasUpdate = true
                    }
                    if(items.name === 'CREATE_PLAN_STAKING_PLATFORM'){
                        this.hasAdd = true
                    }
                    if(items.name === 'UPDATE_PLAN_STAKING_PLATFORM'){
                        this.hasUpdatePlan = true
                    }
                    if(items.name === 'VIEW_LIST_PAYOUT_STAKING_PLATFORM'){
                        this.hasViewPayout = true
                    }
                    if(items.name === 'VIEW_LIST_PLAN_STAKING_PLATFORM'){
                        this.hasViewPlan = true
                    }
                })
            }
        }
    },
    name: "EditERC2O"
};
</script>

<style lang="scss">
    .addERC20-wrap{
        .row{
            margin: 0;
            padding: 0 24px;
            .cardERC-form{
                width: 70%;
                @media only screen and ( max-width: 1024px ){
                    width: 100%;
                }
                .card-body{
                    padding: 42px 15px;
                    
                    .form-group{
                        display: flex;
                        flex-wrap: nowrap;
                        align-items: center;
                        justify-content: flex-start;
                        margin-bottom: 5px;
                        margin-top: 1rem;
                        label{
                            width: 25%;
                            text-align: right;
                            padding-right: 15px;
                            line-height: 25px;
                            color: #3E4B65;
                            // font-family: "SF UI Display";
                            font-size: 14px;
                            font-weight: 500;
                            letter-spacing: 0.5px;
                        }
                        .form-control{
                            width: 55%;
                            @media only screnn and ( max-width: 1024px ){
                                width: 75%;
                            }
                        }
                        &.file-area-group{
                            display: flex;
                            flex-wrap: wrap;
                            .file-area{
                                display: flex;
                                width: 80%;
                                padding-left: 25%;
                                align-items: flex-start;
                                img:not([src=""]){
                                    width: 60px;
                                    align-self: center;
                                    margin-right: 10px;
                                }
                                .ip-file-group{
                                    position: relative;
                                    width: 60px;
                                    min-width: 60px;
                                    height: 60px;
                                    margin-right: 10px;
                                    label{
                                        position: absolute;
                                        width: 100%;
                                        height: 100%;
                                        padding: 5px 10px;
                                        /* font-family: "SF UI Display"; */
                                        font-size: 14px;
                                        font-weight: 500;
                                        letter-spacing: 0.5px;
                                        line-height: 16px;
                                        text-align: center;
                                        margin: 0;
                                        border-radius: 4px;
                                        background: #D8D8D8;
                                    }
                                    #images{
                                        position: absolute;
                                        width: 60px;
                                        height: 60px;
                                        opacity: 0;
                                    }
                                }
                                .label-file-support{
                                    color: #8A93A2;
                                    // font-family: "SF UI Display";
                                    font-size: 10px;
                                    font-weight: 500;
                                    line-height: 16px;
                                    margin: 0;
                                }
                            }
                            .msg-err-img{
                                width: 80%;
                                color: #ee0d0d;
                                // font-family: "SF UI Display";
                                font-size: 14px;
                                font-weight: 300;
                                line-height: 16px;
                                margin: 10px 0 0 0;
                                padding-left: 25%;
                            }
                        }
                    }
                    .form-group--custom{
                        .col-form-label{
                            text-align: right;
                            padding: 0 15px;
                        }
                        .col-sm-9{
                            flex: 0 0 55%;
                            max-width: 55%;
                            input{
                                width: 100%;
                                &[type="number"]{
                                    width: 65px;
                                    &::after{
                                        content: '%';
                                        position: relative;
                                    }
                                }
                            }
                        }
                        &.est-per{
                            .col-sm-9::after{
                                content: '%';
                                position: absolute;
                                font-size: 14px;
                                font-weight: 300;
                                left: 85px;
                                top: 0;
                                line-height: 35px;
                            }
                        }
                        &.sec-label{
                            label{
                                top: -10px;
                                display: flex;
                                flex-direction: column;
                            }
                            // label::after{
                            //     content: '(display-only)';
                            //     position: relative;
                            //     font-size: 12px;
                            //     font-weight: 300;
                            //     color: #8A93A2;
                            //     width: 100%;
                            //     bottom: 5px;
                            //     left: 0;
                            // }
                        }
                    }
                    .label-display-only {
                        font-size: 12px;
                        font-weight: 300;
                        color: #8A93A2;
                        width: 100%;
                        letter-spacing: 0.5px;
                        bottom: 20px;
                        text-align: right;
                    }
                    .row{
                        padding: 0;
                        .col-sm-9{
                            padding: 0;
                            .error-msg{
                                color: #ff0000;
                                margin: 0;
                            }
                        }
                    }
                    .btn-ERC20{
                        width: 100%;
                        margin: 35px 0 0 0;
                        display: flex;
                        justify-content: flex-end;
                        padding-right: 50px;
                        .erc-cancel{
                            color: #657187;
                            // font-family: "SF UI Display";
                            font-size: 14px;
                            font-weight: 500;
                            letter-spacing: 0.5px;
                            text-align: center;
                            background: unset;
                            border: none;
                            outline: none !important;
                            box-shadow: none;
                            margin-right: 50px;
                        }
                        button[type="submit"]{
                            min-width: 146px;
                            height: 36px;
                            border-radius: 4px;
                            background-color: #3B4B7E;
                            color: #FFFFFF;
                            // font-family: "SF UI Display";
                            font-size: 14px;
                            font-weight: 500;
                            letter-spacing: 0.5px;
                            border: none;
                            outline: none !important;
                            box-shadow: none;
                            text-align: center;
                        }
                    }
                }
            }
            .col--padding{
                padding: 0;
                .card-body{
                    overflow-x: auto;
                }
            }
        }
        .btn-new {
            padding: 0;
            width: 170px;
            height: 32px;
            border-radius: 32px;
            background: #3B4B7E;
            border-color: unset;
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;
            float: right;
            margin-bottom: 15px;
        }
        .title-items{
            color: #657187;
            // font-family: "SF UI Display";
            font-size: 18px;
            font-weight: 600;
            line-height: 24px;
        }
    }
.check-status{
    img{
        padding-right: 8px;
    }
}
#leavePageModal{
  .modal-dialog{
    min-width: 400px;
    max-width: 450px;
    .modal-header{
      display: none;
    }
    .modal-body{
      padding: 24px 20px 0 20px;
      .tit-leave{
        margin-bottom: 20px;
        h4{
          color: #4A4A4A;
          /* font-family: "SF UI Display"; */
          font-size: 18px;
          font-weight: 500;
          line-height: 21px;
          margin: 0;
        }
      }
      .text-modal{
        color: #4A4A4A;
        // font-family: "SF UI Display";
        font-size: 14px;
        font-weight: 300;
        line-height: 24px;
        margin-bottom: 0;
      }
    }
    .modal-footer{
      border: none;
      justify-content: space-between;
      padding: 25px 20px 12px 20px;
      .stay-btn{
        border: 1px solid #CBCBCB;
        border-radius: 5px;
        height: 37px;
        line-height: 37px;
        color: #4A4A4A;
        // font-family: "SF UI Display";
        font-size: 14px;
        line-height: 16px;
        padding: 0 20px;
        &:hover,
        &:visited{
          outline: none;
          background: #fcfcfc;
          color: #4A4A4A;
        }
      }
      .leave-btn{
        height: 37px;
        line-height: 37px;
        padding: 0px 20px;
        border-radius: 4px;
        background-color: #3B4B7E;
        color: #FFFFFF;
        // font-family: "SF UI Display";
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.5px;
        &:hover{
          text-decoration: none;
        }
      }
    }
  }
}
.texts-wrap{
display: flex;
flex-direction: column;
button{
    line-height: 10px;
}
span{
    background: #f2f52d;
    color: #000000;
    border-radius: 5px;
    font-size: 12px;
    line-height: 20px;
}
}
</style>